import maplibregl from 'maplibre-gl';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import { MaplibreLegendControl } from "@watergis/maplibre-gl-legend";
import '@watergis/maplibre-gl-legend/dist/maplibre-gl-legend.css';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import { style } from './style_all';

const CACHED_TILES_Z = [13];
const CACHED_TILES_X = [4480, 4481, 4479, 4482];
const CACHED_TILES_Y = [2690, 2691];
maplibregl.addProtocol('custom', (params, callback) => {
    let url = params.url.split('custom://')[1];
    const regex = /\/([\w]+)\/(mvt)\/(\d+)\/(\d+)\/(\d+)$/;
    const match = url.match(regex);
    if (match) {
        const [_, layer, mvt, zStr, xStr, yStr] = match;
        const z = parseInt(zStr);
        const x = parseInt(xStr);
        const y = parseInt(yStr);
        if (CACHED_TILES_Z.includes(z) && CACHED_TILES_X.includes(x) && CACHED_TILES_Y.includes(y)) {
            url = location.origin.indexOf('1234') > -1 ? `/${layer}_${z}_${x}_${y}.pbf` : `/dist-build/${layer}_${z}_${x}_${y}.pbf`
        }
    }
    fetch(url)
        .then(t => {
            if (t.status == 200) {
                t.arrayBuffer().then(arr => {
                    callback(null, arr, null, null);
                });
            } else if (t.status === 204) {
                callback(null, new ArrayBuffer(0), null, null);
            } else {
                callback(new Error(`Tile fetch error: ${t.statusText}`));
            }
        })
        .catch(e => {
            callback(new Error(e));
        });
    return { cancel: () => { } };
});

class MapViewer {
    private map: maplibregl.Map;
    private style: any;
    private hoveredFeatureId: null | number = null;
    private popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false
    });
    private logoUrl = 'https://gis-support.pl/wp-content/themes/GISSupportTheme2/img/logo.png';
    private hoverData: string[][] = [];

    constructor(containerId: string, center: [number, number], zoom: number) {
        this.style = style;
        this.map = new maplibregl.Map({
            container: containerId,
            style: 'https://tiles.stadiamaps.com/styles/alidade_smooth.json',
            // style: {
            //     "version": 8,
            //     "name": "Empty style",
            //     "center": [0, 0],
            //     "zoom": 0,
            //     "sources": {},
            //     "layers": [{
            //         "id": "background",
            //         "type": "background",
            //         "paint": {
            //             "background-color": "#e0dfdf"
            //         }
            //     }],
            // },
            center: center,
            zoom: zoom,
            maxZoom: 20
        });
        this.map.on('load', () => {
            this.addLayers();
            this.addHoverWithPopup();
            this.addLogoToMap(this.logoUrl);
            this.addGeocoder(zoom);
            this.addLegend();
            this.addZoomNotification();
        });
    }

    private addLayers() {
        const source = this.style.sources.tiles;
        const sourceName = 'tiles';
        this.map.addSource(sourceName, {
            type: source.type,
            tiles: [`custom://${source.tiles[0]}`]
            // tiles: source.tiles
        });
        for (const [layerName, layer] of Object.entries(source.layers)) {
            this.map.addLayer({
                'id': layerName,
                // @ts-ignore
                'source': sourceName,
                'source-layer': layer['source-layer'],
                'type': layer['type'],
                'paint': layer['paint'],
                'layout': layer['layout'] ? layer['layout'] : {}
            });
            if (this.style.hover.indexOf(layerName) > -1) {
                this.hoverData.push([
                    sourceName, layer['source-layer']
                ])
            }
        }
    }

    private addHoverWithPopup() {
        this.map.on('mousemove', e => {
            const features = this.map.queryRenderedFeatures(e.point, {
                layers: this.style.hover
            });
            if (this.popup.isOpen()) {
                this.popup.remove();
            }
            if (this.hoveredFeatureId !== null) {
                for (const [sourceName, sourceLayer] of this.hoverData) {
                    this.map.setFeatureState(
                        { source: sourceName, sourceLayer: sourceLayer, id: this.hoveredFeatureId },
                        { hover: false }
                    );
                }
                this.hoveredFeatureId = null;
            }
            const feature = features[0];
            if (feature === undefined || feature.source === undefined || feature.sourceLayer === undefined) {
                return
            }
            const sourceName = feature.source;
            const sourceLayer = feature.sourceLayer;
            const layerName = feature.layer.id;
            if (this.hoveredFeatureId !== feature.id) {
                this.hoveredFeatureId = feature.id as number;
                this.map.setFeatureState(
                    { source: sourceName, sourceLayer: sourceLayer, id: this.hoveredFeatureId },
                    { hover: true }
                );
            }

            const layer = this.style.sources[sourceName].layers[layerName];
            let label = `Warstwa: ${layer.name}<br>`;
            if (layer.id_name.startsWith('properties.')) {
                label += `ID: ${feature.properties[layer.id_name.split('.')[1]]}<br>`
            }
            else {
                label += `ID: ${feature[layer.id_name]}<br>`
            }
            if (layer.area) {
                const area = Math.round(feature.properties[layer.area.name] * 100) / 100
                const areaString = `${area}`.replace('.', ',')
                label += `<a>Powierzchnia: ${areaString} ${layer.area.type}</a><br>`;
            }
            if (layer.attributes && layer.area) {
                for (const [attrName, attrLabel] of Object.entries(layer.attributes)) {
                    let value = Math.round(feature.properties[attrName] * 100) / 100
                    if (value === 0) {
                        const valueString = `${value}`.replace('.', ',')
                        label += `<a style="color:red">${attrLabel}: ${valueString} ${layer.area.type}</a><br>`
                    }
                    else if (feature.properties[layer.area.name] !== feature.properties[attrName]) {
                        const valueString = `${value}`.replace('.', ',')
                        label += `<a>${attrLabel}: ${valueString} ${layer.area.type}<a/><br>`
                    }
                }
            }
            this.map.getCanvas().style.cursor = 'pointer';
            this.popup.setLngLat(e.lngLat)
                .setHTML(label)
                .addTo(this.map);
        })
    }

    private addLegend() {
        const targets = {
            'plots-fill': 'Działki (powierzchnia)',
            'plots-line': 'Działki (obrys)',
            'buildings-fill': 'Budynki (powierzchnia)',
            'buildings-line': 'Budynki (obrys)',
            'power-lines-low': 'Linie energetyczne o niskim napięciu',
            'power-lines-mid': 'Linie energetyczne o średnim napięciu',
            'power-lines-high': 'Linie energetyczne o wysokim napięciu',
            'power-lines-highest': 'Linie energetyczne o najwyższym napięciu',
            'buildings-buffer-fill': 'Bufor 700m od budynków'
        };
        const control = new MaplibreLegendControl(targets, { showDefault: true, onlyRendered: false, title: 'Legenda' });
        this.map.addControl(control, 'bottom-right');

    }

    private addLogoToMap(logoUrl: string): void {
        const logoControl = new maplibregl.LogoControl();
        logoControl.onAdd = function (map: maplibregl.Map) {
            this._img = document.createElement('img');
            this._img.className = 'map-logo';
            this._img.src = logoUrl;
            this._img.style.width = '100px';
            this._container = document.createElement('div');
            this._container.className = 'mapboxgl-ctrl';
            this._container.appendChild(this._img);
            return this._container;
        };
        logoControl.onRemove = function () {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        };
        this.map.addControl(logoControl, 'bottom-left');
    }

    private addGeocoder(zoom: number): void {
        const geocoderApi = {
            forwardGeocode: async (config) => {
                const features: any = [];
                try {
                    const request =
                        `https://nominatim.openstreetmap.org/search?q=${config.query
                        }&format=geojson&polygon_geojson=1&addressdetails=1&accept-language=pl&countrycodes=pl`;
                    const response = await fetch(request);
                    const geojson = await response.json();
                    for (const feature of geojson.features) {
                        const center = [
                            feature.bbox[0] +
                            (feature.bbox[2] - feature.bbox[0]) / 2,
                            feature.bbox[1] +
                            (feature.bbox[3] - feature.bbox[1]) / 2
                        ];
                        const point = {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: center
                            },
                            place_name: feature.properties.display_name,
                            properties: feature.properties,
                            text: feature.properties.display_name,
                            place_type: ['place'],
                            center
                        };
                        features.push(point);
                    }
                } catch (e) {
                    console.error(`Failed to forwardGeocode with error: ${e}`);
                }
                return {
                    features
                };
            }
        };
        const geocoder = new MaplibreGeocoder(geocoderApi, {
            maplibregl,
            showResultsWhileTyping: true,
            zoom
        })
        geocoder.setLanguage('pl')
        this.map.addControl(geocoder);
    }

    private addZoomNotification(): void {
        const zoomNotification = document.createElement('div');
        zoomNotification.id = 'zoom-notification';
        zoomNotification.style.display = 'none';
        zoomNotification.innerText = 'Przybliż mapę, żeby zobaczyć działki';
        this.map.getContainer().appendChild(zoomNotification);
        this.map.on('zoomend', () => {
            if (this.map.getZoom() < 13) {
                zoomNotification.style.display = 'block';
            } else {
                zoomNotification.style.display = 'none';
            }
        });
    }

}

new MapViewer('map', [16.9071342, 52.4042059], 13);
